import styled from 'styled-components';
import { colorFluerecente, colorOscuro, fuenteTitulos } from '../variables';

export const LeftProducts = styled.div`
  display: flex;
  padding: 0 ${props => props.invert ? '8em 4em 0' : '0 4em 8em'};
  gap: 1em;
  align-self: center;

  & strong{
      font-family: ${fuenteTitulos};
      font-size: 10rem;
      color: ${colorOscuro};
  }

  & h2{
      text-transform: uppercase;
      color: ${colorOscuro};
      font-weight: 900;
      
  }
  & h2 strong,& p strong{
    font-size: inherit;
    color: ${colorFluerecente};
  }

  & p{
      color: black;
  }
  @media (max-width: 500px){
    padding: 0 2em;
    flex-direction: column;
  }
`;

export const RightProducts = styled.div`
  background: ${colorOscuro};
  border-radius: 8px;
  padding: 4em;
  max-height: 400px;
  box-sizing: border-box;
  margin-bottom: 6em;
`;
import { graphql, useStaticQuery } from "gatsby"

export const useProduct = () => {
  const product = useStaticQuery(
    graphql`
      query {
        allStrapiProduct {
          edges {
            node {
              id
              Number
              Name
              title
              titulo
              descripcion
              Description
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return product.allStrapiProduct.edges
}

import * as React from "react"

import Seo from "../components/seo"
import Hero from "../components/Hero"
import ProductItem from "../components/ProductItem"
import { Button, Container } from "../styles"
import { products } from "../api/db.json"
import { useTranslate } from "react-translate"
import { usePages } from "../hooks/usePages"
import { useItems } from "../hooks/useItems"
import { useProduct } from "../hooks/useProduct"
import { useLang } from "../hooks/useLang"

const ProductDesign = ({ pageContext }) => {
  const [lang, setLang] = useLang("en")

  const buttonText = useItems(
    lang === "en" ? "Button English" : "Button Spanish"
  ).content[0].text

  const products = useProduct()

  const { name } = pageContext

  const page = usePages(name)

  const info = page.Cuerpo[0]

  return (
    <>
      <Seo title={info.Title} />
      <Hero img={"product-design-page.png"} title={info.Title} />
      <Container>
        <p>{info.Description}</p>
      </Container>

      {products.map(({ node }, index) => {
        let left = index % 2

        return <ProductItem key={node.key} left={left} {...node} />
      })}

      <Button to="/contact" big={true}>
        {buttonText}
      </Button>
    </>
  )
}

export default ProductDesign

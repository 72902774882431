import React, { useEffect, useState } from "react"
import { Grid } from "../styles"
import { LeftProducts, RightProducts } from "../styles/components/ProductItem"
import * as ImageComponent from "./Image"
import ReactHtmlParser from "react-html-parser"
import { useLang } from "../hooks/useLang"
import marked from "marked"
import Img from "gatsby-image"

export default function ProductItem(props) {
  const {
    Name,
    Number,
    Description,
    descripcion,
    image,
    left,
    title,
    titulo,
  } = props
  const [isLeft, setIsLeft] = useState(left)
  const [lang, setLang] = useLang()

  const name = lang === "en" ? title : titulo
  const description = lang === "en" ? Description : descripcion

  let viewportWidth

  useEffect(() => {
    if (typeof window != "undefined") {
      viewportWidth = window.innerWidth

      // si se esta en dispositivos moviles no se va a invertir las columnas
      if (viewportWidth <= 500) {
        setIsLeft(false)
      }
    }
  })

  const columns = left ? "400px 1fr" : "1fr 400px"

  console.log(props)
  console.log(image)

  return (
    <Grid custom={columns} invert={isLeft} gap={4}>
      <LeftProducts invert={left}>
        <div>
          <strong>0{Number}</strong>
        </div>
        <div>
          {ReactHtmlParser(marked(name))}
          <p>{ReactHtmlParser(marked(description))}</p>
        </div>
      </LeftProducts>
      <RightProducts invert={left}>
        <Img fluid={image.localFile.childImageSharp.fluid} />
      </RightProducts>
    </Grid>
  )
}
